import { useQuery } from "@apollo/client";
import { Modal } from "@sede-x/shell-ds-react-framework";
import { useTheme } from "contexts/ThemeContext";
import { loader } from "graphql.macro";
import { JsonView, allExpanded, darkStyles, defaultStyles } from "react-json-view-lite";
import { ApolloErrorViewer } from "shared/components/ApolloErrorViewer";
import InlineLoadingPanel from "shared/components/InlineLoadingPanel";
import { GqlResponse } from "types";
import { TJsonObject } from "./tracker.types";
import { FETCH_POLICY_NO_CACHE } from "./util";

const inboundContractRequestPayload = loader("./graphql/inboundContractPayload.graphql");

type TInboundContractPayloadResponse = GqlResponse<TJsonObject, "inboundContractPayload">;

export const ContractJsonViewer = ({
  requestId,
  open,
  onClose
}: {
  requestId: string;
  open: boolean;
  onClose: () => void;
}) => {
  const { theme } = useTheme();
  const { loading, error, data } = useQuery<TInboundContractPayloadResponse>(
    inboundContractRequestPayload,
    {
      fetchPolicy: FETCH_POLICY_NO_CACHE,
      variables: { id: requestId }
    }
  );
  const customStyles = {
    ...(theme === "light" ? defaultStyles : darkStyles),
    container: "movement-degug-info",
    basicChildStyle: "basic-element-style"
  };

  return (
    <Modal
      title="Inbound Contract Update Request"
      width={"800px"}
      height={"600px"}
      mask={true}
      bodyPadding={false}
      open={open}
      onClose={onClose}>
      <div
        style={{
          maxHeight: "600px",
          overflowY: "auto",
          padding: "4px"
        }}>
        {loading && <InlineLoadingPanel />}
        {error && <ApolloErrorViewer error={error} />}
        {data && (
          <JsonView
            data={data.inboundContractPayload}
            shouldExpandNode={allExpanded}
            style={customStyles}
            clickToExpandNode={true}
          />
        )}
      </div>
    </Modal>
  );
};
