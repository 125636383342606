import {
  Avatar,
  Directions,
  Flexbox,
  Menu,
  NavBar,
  Pecten,
  Sizes,
  Toggle
} from "@sede-x/shell-ds-react-framework";
import {
  BarChart,
  Bell,
  CarbonDioxide,
  Grid,
  Order
} from "@sede-x/shell-ds-react-framework/build/esm/components/Icon/components";
import { ItemType } from "@sede-x/shell-ds-react-framework/build/esm/components/Menu/Menu.types";
import { MackPrivilege } from "auth";
import { AuthenticationContext } from "auth/AuthenticationProvider";
import { ReactNode, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTheme } from "../../contexts/ThemeContext";
import { Header } from "./Header";

type DrawerRouterContainerProps = {
  children: ReactNode;
};

const linkComponent = (props: { path: string; children: ReactNode | string }) => {
  return (
    <Link to={props.path} onClick={() => {}} className="shell-menu-label">
      {props.children}
    </Link>
  );
};

const DrawerRouterContainer = (props: DrawerRouterContainerProps) => {
  const location = useLocation();
  const authContext = useContext(AuthenticationContext);
  const mackUser = authContext.mackUser;

  const pathName = location.pathname;

  // @ts-ignore
  const { theme, toggleTheme } = useTheme();

  const routerMenuItems: ItemType[] = [
    {
      key: "1",
      label: "Dashboard",
      itemIcon: <Grid />,
      path: "/dashboard"
    },
    {
      key: "2",
      label: "Forecast",
      itemIcon: <BarChart />,
      children: [
        {
          key: "2-1",
          label: "Search Criteria",
          path: "/forecast"
        },
        {
          key: "2-2",
          label: "Export",
          path: "/forecast/export"
        },
        {
          key: "2-3",
          label: "Import",
          path: "/forecast/import"
        }
      ],
      className: mackUser?.hasForcastPrivilege() ? "" : "hidden"
    },
    {
      key: "3",
      label: "Ticketing",
      itemIcon: <Order />,
      children: [
        {
          key: "3-1",
          label: "Movements",
          path: "/ticketing"
        },
        {
          key: "3-2",
          label: "Tickets",
          path: "/ticketing/manageTickets"
        },
        {
          key: "3-3",
          label: "Bulk Import Tickets",
          path: "/ticketing/bulkImport"
        },
        {
          key: "3-4",
          label: "Ops Validations",
          path: "/ticketing/opsvalidations"
        },
        {
          key: "3-5",
          label: "Trackers",
          path: "/ticketing/trackers/outboundMovements",
          className: mackUser?.hasEndurConnectorPrivilege() ? "" : "hidden"
        }
      ],
      className: mackUser?.hasMovementsOrTicketingPrivilege() ? "" : "hidden"
    },
    {
      key: "4",
      label: "Carbon IQ",
      itemIcon: <CarbonDioxide />,
      path: "/carboniq",
      children: [
        {
          key: "4-1",
          label: "Savings Letters",
          path: "/carboniq"
        },
        {
          key: "4-2",
          label: "Customers",
          path: "/carboniq/customers"
        },
        {
          key: "4-3",
          label: "Products",
          path: "/carboniq/products"
        },
        {
          label: "Product Blend",
          key: "4-4",
          path: "/carboniq/product-blend"
        },
        {
          label: "Blend Components",
          key: "4-5",
          path: "/carboniq/blend-components"
        },
        {
          label: "Terminals",
          key: "4-6",
          path: "/carboniq/terminals"
        },
        {
          label: "Weighted Averages",
          key: "4-7",
          path: "/carboniq/weighted-averages"
        }
      ],
      className: mackUser?.hasAnyPrivilegeFromService("CARBONIQ") ? "" : "hidden"
    },
    {
      key: "5",
      label: "Notification",
      itemIcon: <Bell />,
      path: "/notifications"
    }
  ];

  const adminRouterMenuItems: ItemType[] = [
    {
      label: "PREFERENCES",
      type: "group",
      children: [
        {
          key: "9-1",
          className: "theme-btn-wrap",
          label: (
            <Flexbox justifyContent="space-between">
              <span>{theme === "light" ? "Light" : "Dark"} Mode</span>
              <Toggle
                label=""
                checked={theme === "light"}
                onChange={toggleTheme}
                size={Sizes.Medium}
              />
            </Flexbox>
          )
        }
      ]
    },
    {
      label: "General",
      type: "group",
      children: [
        {
          key: "9-2",
          label: "Users",
          path: "/settings/manage/users",
          className: mackUser?.hasPrivilege(MackPrivilege.ViewUsers) ? "" : "hidden"
        },
        {
          key: "9-3",
          label: "Roles",
          path: "/settings/manage/roles",
          className: mackUser?.hasPrivilege(MackPrivilege.ViewRoles) ? "" : "hidden"
        },
        {
          key: "9-4",
          label: "Managed Notifications",
          path: "/settings/manage/subscriptions"
        }
      ]
    }
  ];

  const selected = () => {
    const tempPathItem = routerMenuItems.find(
      //@ts-ignore
      item => item?.path === pathName
    );
    switch (pathName) {
      case "/ticketing":
      case "/ticketing/bulkImport":
        return { pageName: "Ticketing", description: "Ticketing Description" };

      case "/forecast":
      case "/forecast/export":
      case "/forecast/import":
        return { pageName: "Forecast", description: "Forecast Description" };

      default:
        //@ts-ignore
        return { pageName: tempPathItem?.label, pathName: tempPathItem?.path };
    }
  };

  return (
    <>
      <Header />
      <div className="app-drawer-wrapper">
        <NavBar
          size={Sizes.Large}
          direction={Directions.Vertical}
          items={routerMenuItems}
          elevation
          itemRender={linkComponent}
          pectenArea={
            <Link to={"/"} onClick={() => {}} className="company-logo-wrapper">
              <Pecten size={Sizes.Medium} className={"company-logo"} />
              <span className="company-name">MACk</span>
            </Link>
          }
          avatarArea={
            <div>
              <Avatar
                size={Sizes.Large}
                abbreviation={`${mackUser?.mackUser.firstName.charAt(
                  0
                )}${mackUser?.mackUser.lastName.charAt(0)}`}
                label={`${mackUser?.mackUser.firstName} ${mackUser?.mackUser.lastName}`}
                className="avatar-wrap"
                dropdownOverlay={
                  <div
                    style={{ maxWidth: "250px", paddingBottom: "10px" }}
                    className="admin-sidebar-menu-wrapper">
                    <Menu
                      items={adminRouterMenuItems}
                      selectedPath={pathName}
                      itemRender={linkComponent}
                    />
                  </div>
                }
              />
            </div>
          }
          selectedPath={selected().pathName || pathName}
          expandedWidth={200}
          className="sidebar-menu"
        />
        <div className="main-container-wrapper">
          <div className="main-container hidden-scrollbar">{props.children}</div>
        </div>
      </div>
    </>
  );
};

export default DrawerRouterContainer;
