import { ApolloError, useQuery } from "@apollo/client";
import { MutationFetchPolicy } from "@apollo/client/core/watchQueryOptions";
import {
  Badge,
  BaseTable,
  Button,
  Sentiments,
  Sizes,
  Variants
} from "@sede-x/shell-ds-react-framework";
import {
  AddSquare,
  CheckCircleSolid,
  CrossCircleSolid,
  EditOutlined
} from "@sede-x/shell-ds-react-framework/build/esm/components/Icon/components";
import {
  ColumnDef,
  SortingState,
  getPaginationRowModel,
  getSortedRowModel
} from "@tanstack/react-table";
import { IAppState } from "_redux/IReduxState";
import { ROWS_PER_PAGE } from "carbonIQ/CarbonIQConstants";
import CarbonIQEmptyTable from "carbonIQ/emptyTable";
import dayjs from "dayjs";
import ColumnText from "global/elements/columnText";
import GlobalHeader from "global/sections/header";
import { loader } from "graphql.macro";
import { useState } from "react";
import { useSelector } from "react-redux";
import { ApolloErrorViewer } from "shared/components/ApolloErrorViewer";
import {
  SHELL_CHEM_LE,
  SOPUS_LE,
  SOPUS_SANDBOX_LE,
  STCAN_LE,
  STUSCO_LE
} from "ticketing/utils";
import { GqlResponse } from "types";
import { IMACkRole, IMACkRoleView, IMACkUser } from "../../../auth";
import InlineLoadingPanel from "../../../shared/components/InlineLoadingPanel";
import { TUserLegalEntity } from "../types";
import UserEdit from "./UserEdit";

const LEGAL_ENTITY_NAMES = [STCAN_LE, STUSCO_LE, SOPUS_LE, SOPUS_SANDBOX_LE, SHELL_CHEM_LE];

const GET_ALL_USERS = loader("../graphql/query-users.graphql");
const GET_ALL_ROLES = loader("../graphql/query-active-roles.graphql");
const GET_ALL_USER_LEGAL_ENTITIES = loader("../graphql/query-internal-legal-entities.graphql");

type TAllUsersData = GqlResponse<IMACkUser[], "allUsers">;
type TAllRolesData = GqlResponse<IMACkRoleView[], "allRoles">;
type TAllLegalEntities = GqlResponse<TUserLegalEntity[], "userLegalEntities">;
const FETCH_POLICY_NO_CACHE = { fetchPolicy: "no-cache" as MutationFetchPolicy };

const gridColumns: ColumnDef<IMACkUser>[] = [
  {
    header: "ID",
    accessorKey: "id",
    enableSorting: true,
    enableResizing: false,
    cell: arg => <ColumnText width={"70px"}>{arg.getValue() as string}</ColumnText>
  },

  {
    header: "First Name",
    accessorKey: "firstName",
    enableResizing: false,
    enableSorting: true,
    cell: arg => <ColumnText width={"100px"}>{arg.getValue() as string}</ColumnText>
  },

  {
    header: "Last Name",
    accessorKey: "lastName",
    enableResizing: false,
    enableSorting: true,
    cell: arg => <ColumnText width={"100px"}>{arg.getValue() as string}</ColumnText>
  },
  {
    header: "Email",
    accessorKey: "email",
    enableResizing: false,
    enableSorting: true,
    cell: arg => (
      <p
        style={{
          width: "200px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis"
        }}
        title={arg.getValue() as string}>
        {arg.getValue() as string}
      </p>
    )
  },

  {
    header: "Status",
    accessorKey: "status",
    enableResizing: false,
    enableSorting: true,
    cell: arg => (
      <p style={{ width: "80px" }}>
        {(arg.getValue() as string).toLowerCase() === "active" ? (
          <CheckCircleSolid fill={"var(--forest-500)"} />
        ) : (
          <CrossCircleSolid fill={"var(--red-500)"} />
        )}
      </p>
    )
  },

  {
    header: "Role(s)",
    accessorKey: "roles",
    enableResizing: false,
    cell: arg => {
      const tempValue: string = (arg.getValue() as IMACkRole[])
        .map((elm: { name: string }) => elm.name)
        .join(", ");
      return (
        <p
          style={{
            width: "100px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis"
          }}
          title={tempValue}>
          {tempValue}
        </p>
      );
    }
  },
  {
    header: "Last Sign-In At",
    accessorKey: "lastSignInAt",
    enableResizing: false,
    enableSorting: true,
    cell: arg => {
      const tempValue = arg.getValue() as string;
      return (
        <p style={{ width: "100px" }}>
          {tempValue ? dayjs(tempValue).format("MM/DD/YYYY") : "N/A"}
        </p>
      );
    }
  }
];

const gridColumnsGenerate = (
  clickcallback: (arg: {
    row: {
      original: IMACkUser;
    };
  }) => void
) => {
  const additionalGridColumn = {
    header: "",
    accessorKey: "version",
    size: 50,
    enableSorting: false,
    cell: (arg: {
      row: {
        original: IMACkUser;
      };
    }) => (
      <div className="cta-button-wrapper">
        <span>
          <Button
            title="Edit"
            size={Sizes.Small}
            onClick={() => {
              clickcallback(arg);
            }}>
            <EditOutlined />
            Edit
          </Button>
        </span>
      </div>
    )
  };

  return [...gridColumns, additionalGridColumn];
};

const Users = () => {
  const { loading, error: uError } = useQuery<TAllUsersData>(GET_ALL_USERS, {
    ...FETCH_POLICY_NO_CACHE,
    onCompleted: data => setUsers(JSON.parse(JSON.stringify(data?.allUsers)))
  });

  const {
    loading: rLoading,
    data: rolesData,
    error: rError
  } = useQuery<TAllRolesData>(GET_ALL_ROLES, { ...FETCH_POLICY_NO_CACHE });

  const selectedEnterpriseSystem = useSelector(
    (state: IAppState) => state.ticketing?.selectedEnterpriseSystem
  );

  const {
    loading: lLoading,
    data: legalEntitiesData,
    error: lError
  } = useQuery<TAllLegalEntities>(GET_ALL_USER_LEGAL_ENTITIES, {
    skip: !selectedEnterpriseSystem,
    variables: {
      enterpriseSystemId: selectedEnterpriseSystem?.id,
      legalEntityNames: LEGAL_ENTITY_NAMES
    },
    ...FETCH_POLICY_NO_CACHE
  });

  const [users, setUsers] = useState<IMACkUser[]>([]);
  const [selectedUser, setSelectedUser] = useState<IMACkUser | null>();

  const onNewUser = () => {
    setSelectedUser({
      id: "",
      version: 0,
      firstName: "",
      lastName: "",
      email: "",
      status: "ACTIVE",
      defaultLegalEntityId: null,
      roles: []
    });
    setAddOrUpdateOpen(true);
  };

  const onEditUser = (user: IMACkUser) => {
    setSelectedUser(user);
    setAddOrUpdateOpen(true);
  };

  const onEditDone = (result: IMACkUser | null) => {
    if (result != null) {
      if (users.findIndex(u => u.id === result.id) > -1) {
        setUsers(users.map(u => (u.id === result.id ? result : u))); //replace if found
      } else {
        setUsers([...users, result]);
      }
    }
    setSelectedUser(null);
    setAddOrUpdateOpen(false);
  };

  const columns = gridColumnsGenerate(arg => onEditUser(arg.row.original));

  const [sorting, setSorting] = useState<SortingState>([]);

  const [pagination, setPagination] = useState({
    pageIndex: 0, //initial page index
    pageSize: ROWS_PER_PAGE //default page size
  });

  const tableOptions = {
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: {
      sorting,
      pagination
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    autoResetPageIndex: false
  };
  const [addOrUpdateOpen, setAddOrUpdateOpen] = useState(false);

  const isLoading = [loading, rLoading, lLoading].some(l => l);
  const errors = [uError, rError, lError].filter((e): e is ApolloError => Boolean(e));
  return (
    <>
      <GlobalHeader
        pageName="Manage Users"
        buttonContent={[
          <Badge
            key={1}
            icon={<AddSquare />}
            sentiment={Sentiments.Information}
            variant={Variants.Filled}
            onClick={() => onNewUser()}>
            Add New User
          </Badge>
        ]}
      />

      {isLoading && <InlineLoadingPanel />}
      {(errors?.length ?? 0) > 0 && <ApolloErrorViewer error={errors}></ApolloErrorViewer>}

      {users.length > 0 ? (
        <BaseTable
          maxHeight={600}
          columns={columns}
          data={users}
          className="carboniq-data-table"
          tableOptions={tableOptions}
        />
      ) : (
        <CarbonIQEmptyTable />
      )}

      {addOrUpdateOpen && selectedUser && (
        <UserEdit
          onClose={() => setAddOrUpdateOpen(!addOrUpdateOpen)}
          user={selectedUser}
          onEditDone={onEditDone}
          roles={rolesData?.allRoles ?? []}
          legalEntities={legalEntitiesData?.userLegalEntities ?? []}
        />
      )}
    </>
  );
};

export default Users;
