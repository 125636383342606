import { useQuery } from "@apollo/client";
import { BaseTable, Flexbox } from "@sede-x/shell-ds-react-framework";
import { ColumnDef } from "@tanstack/react-table";
import dayjs from "dayjs";
import GlobalHeader from "global/sections/header";
import { loader } from "graphql.macro";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ApolloErrorViewer } from "shared/components/ApolloErrorViewer";
import InlineLoadingPanel from "shared/components/InlineLoadingPanel";
import { GqlResponse } from "ticketing/ticketing.types";
import { CargoXmlMessageViewer } from "./CargoXmlMessageViewer";
import { DateCell, makeButtonCell, TextCell } from "./cells";
import { OutboundBatchesSearch } from "./OutboundBatchesSearch";
import { SearchButton } from "./SearchButton";
import { PagedConnection, TOutboundBatch, TOutboundBatchSearchCriteria } from "./tracker.types";
import { TrackerNavbar } from "./TrackerNavbar";
import { TrackerPager } from "./TrackerPager";
import { toBatcUpdateQueueFilterInput } from "./util";

const batchUpdatesQueueFilterBy = loader("./graphql/batchUpdatesQueueFilterBy.graphql");
const FETCH_POLICY_NO_CACHE = "no-cache";
type TOutboundBatchQueueResponse = GqlResponse<
  PagedConnection<TOutboundBatch>,
  "batchUpdatesQueueFilterBy"
>;
type TSearchState = {
  showModal: boolean;
  criteria: TOutboundBatchSearchCriteria;
};
const pathName = "/ticketing/trackers/outboundBatches";

const PADDING = 48;
const DEFULT_PAGE_SIZE = 25;
const INIT_SEARCH_CRITERIA = {
  startDate: dayjs().add(-1, "day").startOf("day").toISOString(),
  endDate: dayjs().endOf("day").toISOString()
};

export const OutboundBatchesTracker = () => {
  const [searchModalState, setSearchModalState] = useState<TSearchState>({
    showModal: false,
    criteria: INIT_SEARCH_CRITERIA
  });
  const [showMessage, setShowMessage] = useState({ show: false, cargoMessageId: "" });

  const { loading, error, data, refetch } = useQuery<TOutboundBatchQueueResponse>(
    batchUpdatesQueueFilterBy,
    {
      fetchPolicy: FETCH_POLICY_NO_CACHE,
      nextFetchPolicy: FETCH_POLICY_NO_CACHE,
      variables: {
        filter: toBatcUpdateQueueFilterInput({ ...INIT_SEARCH_CRITERIA }),
        first: DEFULT_PAGE_SIZE
      }
    }
  );
  const onShowMessage = useCallback((outboundBatchMessage: TOutboundBatch) => {
    setShowMessage({ show: true, cargoMessageId: outboundBatchMessage.cargoMessageId });
  }, []);

  const gridColumns: ColumnDef<TOutboundBatch>[] = useMemo(
    () => [
      {
        header: "Cargo Name",
        accessorKey: "name",
        cell: TextCell
      },
      {
        header: "Updated By",
        accessorKey: "updatedBy",
        size: 120,
        cell: TextCell
      },
      {
        header: "Queued At",
        accessorKey: "receivedAt",
        size: 160,
        cell: DateCell
      },
      {
        header: "Scheduled At",
        accessorKey: "scheduledAt",
        size: 160,
        cell: DateCell
      },
      {
        header: "Processed At",
        accessorKey: "processedAt",
        size: 160,
        cell: DateCell
      },
      {
        header: "Status",
        accessorKey: "status",
        size: 120,
        cell: TextCell
      },
      {
        header: "Num Retries",
        accessorKey: "numRetries",
        cell: TextCell
      },
      {
        header: "Next Retry At",
        accessorKey: "nextRetryAt",
        size: 160,
        cell: TextCell
      },
      {
        header: "Reason",
        accessorKey: "statusReason",
        cell: TextCell
      },
      {
        header: "Xml Message Id",
        accessorKey: "xmlMessageId",
        cell: TextCell
      },
      {
        header: "Xml",
        accessorKey: "cargoMessageId",
        cell: makeButtonCell(onShowMessage)
      }
    ],
    [onShowMessage]
  );

  const searchFormRef = useRef<HTMLDivElement>(null);
  const [top, setTop] = useState<number>(0);
  useEffect(() => {
    const { innerHeight } = window;
    setTop(
      innerHeight -
        (searchFormRef?.current?.getBoundingClientRect().top ?? 0) -
        (searchFormRef?.current?.getBoundingClientRect().height ?? 0) -
        PADDING
    );
  }, [searchFormRef]);

  const onPrevious = useCallback(() => {
    const startCursor = data?.batchUpdatesQueueFilterBy?.pageInfo?.startCursor;
    refetch({
      before: startCursor ?? null,
      after: null,
      last: startCursor ? DEFULT_PAGE_SIZE : null,
      first: !startCursor ? DEFULT_PAGE_SIZE : null
    });
  }, [refetch, data]);

  const onNext = useCallback(() => {
    const endCursor = data?.batchUpdatesQueueFilterBy?.pageInfo?.endCursor;
    refetch({
      before: null,
      after: endCursor ?? null,
      first: DEFULT_PAGE_SIZE,
      last: null
    });
  }, [refetch, data]);

  return (
    <div>
      <GlobalHeader
        pageName="Outbound Batches Queue"
        buttonContent={[
          <SearchButton
            key={"batchSearchButton"}
            onClick={() => setSearchModalState({ ...searchModalState, showModal: true })}
          />
        ]}
      />
      <TrackerNavbar selectedPath={pathName} />
      {loading && <InlineLoadingPanel />}
      {error && <ApolloErrorViewer error={error} />}
      <div ref={searchFormRef}>
        <OutboundBatchesSearch
          open={searchModalState.showModal}
          searchCriteria={searchModalState.criteria}
          onClose={() => setSearchModalState({ ...searchModalState, showModal: false })}
          onSubmit={criteria => {
            setSearchModalState({ showModal: false, criteria });
            refetch({ filter: toBatcUpdateQueueFilterInput(criteria) });
          }}
        />
      </div>
      <Flexbox flexDirection="column" gap="8px">
        <BaseTable
          size={"small"}
          useColumnsSizing={true}
          columns={gridColumns}
          maxHeight={top}
          data={data?.batchUpdatesQueueFilterBy.edges.flatMap(e => e.node)}></BaseTable>
        <TrackerPager onPrevious={onPrevious} onNext={onNext} />
      </Flexbox>
      {showMessage.show && (
        <CargoXmlMessageViewer
          messageId={showMessage.cargoMessageId}
          open={showMessage.show}
          onClose={() => setShowMessage({ show: false, cargoMessageId: "" })}
        />
      )}
    </div>
  );
};
